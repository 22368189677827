
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { GlossaryItemMode } from '@/modules/system/types';
import { IGlossaryItem } from '@/common/types';

@Component
export default class BaseGlossaryItem extends Vue {
  @Prop(String)
  readonly code: string | undefined;

  @Prop(String)
  readonly glossary: string | undefined;

  @Prop({ default: 'chip' })
  readonly mode!: GlossaryItemMode;

  @Prop(Boolean)
  readonly small: boolean | undefined;

  @Prop(Boolean)
  readonly large: boolean | undefined;

  @Prop(Boolean)
  readonly top: boolean | undefined;

  @Prop(Boolean)
  readonly bottom: boolean | undefined;

  @Prop(Boolean)
  readonly left: boolean | undefined;

  @Prop(Boolean)
  readonly right: boolean | undefined;

  get item(): IGlossaryItem<any> | undefined {
    return $app.item(this.glossary!, this.code!);
  }
}
